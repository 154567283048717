import React from 'react';

//importing the css
import '../../css/navigation/SectionNav.css';

function SectionNavPos({currentSection, setSection, ...props}) {
    return ( 
        <>
        <div className="sectionnavwrapper">
            <div className="navsectioncontentwrapper">
                <ul>
                    <li onClick={() => setSection('Warenposition')} className={currentSection === "Warenposition" ? "activesection" : ""}><span>Warenposition</span></li>
                    <li onClick={() => setSection('Bewilligung')} className={currentSection === "Bewilligung" ? "activesection" : ""}><span>Bewilligung</span></li>
                    <li onClick={() => setSection('Lieferketten-Beteiligter')} className={currentSection === "Lieferketten-Beteiligter" ? "activesection" : ""}><span>Lieferketten-Beteiligter</span></li>
                    <li onClick={() => setSection('Taric-Zusatzcode')} className={currentSection === "Taric-Zusatzcode" ? "activesection" : ""}><span>Taric-Zusatzcode</span></li>
                    <li onClick={() => setSection('Gefahrgut')} className={currentSection === "Gefahrgut" ? "activesection" : ""}><span>Gefahrgut</span></li>
                    <li onClick={() => setSection('Verpackung')} className={currentSection === "Verpackung" ? "activesection" : ""}><span>Verpackung</span></li>
                    <li onClick={() => setSection('Vorpapier')} className={currentSection === "Vorpapier" ? "activesection" : ""}><span>Vorpapier</span></li>
                    <li onClick={() => setSection('Unterlage')} className={currentSection === "Unterlage" ? "activesection" : ""}><span>Unterlage</span></li>
                    <li onClick={() => setSection('Sonstiger Verweis')} className={currentSection === "Sonstiger Verweis" ? "activesection" : ""}><span>Sonstiger Verweis</span></li>
                    <li onClick={() => setSection('Zusätzliche Informationen')} className={currentSection === "Zusätzliche Informationen" ? "activesection" : ""}><span>Zusätzliche Informationen</span></li>
                    <li onClick={() => setSection('Verfahrensübergang/Zolllager')} className={currentSection === "Verfahrensübergang/Zolllager" ? "activesection" : ""}><span>Verfahrensübergang/Zolllager</span></li>
                    <li onClick={() => setSection('Verfahrensübergang/Aktive Veredelung')} className={currentSection === "Verfahrensübergang/Aktive Veredelung" ? "activesection" : ""}><span>Verfahrensübergang/Aktive Veredelung</span></li>
                </ul>
            </div>
        </div>
        </>
     );
}

export default SectionNavPos;