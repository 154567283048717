import React, { useState, useEffect } from 'react';

//importing the css
import './PositionSelect.css';

function PositionSelect({options, value, onChange, ...props}) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(value);

    const handleToggle = () => setIsOpen(!isOpen);

    const handleSelect = (value, code, id) => {
        setSelectedValue(`${code} - ${value}`);
        onChange({ target: { value } });
    
        // Handle the logic for setting the current position
        const Positions = JSON.parse(localStorage.getItem('rb-position-data-sales-head'));

        const selectedPosition = Positions.find(position => position.id === id); //


        if (selectedPosition) {
            localStorage.setItem('rb-current-position', JSON.stringify(selectedPosition));
        }
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (e.target.closest('.textdropdownwrapper') !== null) {
                return;
            } else {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            window.addEventListener('click', handleClickOutside);
        } else {
            window.removeEventListener('click', handleClickOutside);
        }

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen]);



    useEffect(() => {

        const isFirstPosition = JSON.parse(localStorage.getItem('rb-current-position'));


        if (isFirstPosition) {
            setSelectedValue(`${1} - ${isFirstPosition.material.name}`);
        }

    }, [value, options]);


    return ( 
        <>
        <div className="textdropdownwrapper" style={{zIndex: props.zindex}}>
            <div className="dropdownnamewrapper">
                <span>{props.fieldname}</span>
            </div>
            <div className="textdropdown_btn_wrapper">
                <div className="textdropdownrowwrapper">
                    <button onClick={handleToggle}>
                        <input 
                        type="text" 
                        placeholder='Bitte auswählen...'
                        value={selectedValue}
                        onChange={onChange}
                        readOnly
                        />
                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#5f6368">
                            <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z"/>
                        </svg>
                    </button>

                    <div className="addbutton">
                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#5f6368">
                            <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/>
                        </svg>
                    </div>
                </div>

                <div className={`textdropdown_content_box ${isOpen ? 'dropdown_visible' : ''}`}>
                    <ul>
                    {options?.map((option, index) => (
                        <li key={option.id} onClick={() => handleSelect(option.material.name, index + 1, option.id)}>
                            <span>Pos. {index + 1} - {option.material.name}</span> {/* index + 1, um bei 1 zu beginnen statt bei 0 */}
                        </li>
                    ))}
                    </ul>
                </div>
            </div>
        </div>
        </>
     );
}

export default PositionSelect;
