import React, {useEffect, useState} from 'react';
//importing the Compoenents
import MainHeader from '../../components/header/MainHeader';
import HeadLine from '../../components/toolbar/HeadLine';
import SectionNav from '../../components/navigation/SectionNav';
import SectionNavPos from '../../components/navigation/SectionNavPos';
import HeaderInformation from '../../components/sections/createatlasmessage/Header/HeaderInformation';
import ApprovalSection from '../../components/sections/createatlasmessage/Header/ApprovalSection';
import SupplyChainParticipant from '../../components/sections/createatlasmessage/Header/SupplyChainParticipant';
import ReimportSection from '../../components/sections/createatlasmessage/Header/ReimportSection'; 
import FullPageLoading from '../../components/Loadinganimation/FullPageLoading';

import Inputfield from '../../components/inputfields/InputField';
import TextArea from '../../components/inputfields/TextArea/TextArea';
import TextDropDownButton from '../../components/inputfields/TextDropDownButton';
import PositionSelect from '../../components/inputfields/SelectPosition/PositionSelect';
import DropDown from '../../components/inputfields/DropDown';
import AccordionForm from '../../components/forms/AccordionForm';

//Importing the css
import '../../css/pages/CreateAtlasMessage.css';
import '../../css/toolbar/ToolBar.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';


//importing the base urls
import base from '../../apis/base.json';



//Importing Utils
import { MapArticleData } from '../../utils/Filter/ReyBeyArticlesInLS/ReyBexArticlesInLs';


import { sendAtlasData } from '../../apis/POST/ATLAS/PutAtlasMessage';



function CreateAtlasMessage() {
    const [positionSection, setPositionSection] = useState(false);
    const [SectionActive, setSectionActive] = useState("Kopfdaten"); 

    //ATLAS - CUSTOMER - OPTIONS
    const CustomerOptions = useSelector((state) => state.CustomerOptions.data);

    const goto = useNavigate();

    const handlePosition = () => {
        setPositionSection(!positionSection);
        handleStartPoint();
    }

    const handleStartPoint = () => {
        if (SectionActive === "Kopfdaten") {
            setSectionActive("Warenposition");
        } else {
            setSectionActive("Kopfdaten");
        }
    }

    //set AtlasStructure to local storage: 
    const location = useLocation();


    //Getting the Data information for the loading animation: 
    const IsDataValid = useSelector((state) => state.CustomerOptions.data);

    const {reybexlsid} = useParams();

    const HandlePositionGet = async () => {

        try {
            const response = await fetch(`${base.base_reybex}domains/salesItem?salesHead.id=${reybexlsid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Basic ${localStorage.getItem("Rtoken")}`
                }
            });

            if (!response.ok) {
                throw new Error('Netzwerkantwort war nicht ok');
            } else {
                const data = await response.json();
                console.log(data);
                //set it to the local storage (temporary)
                localStorage.setItem('rb-position-data-sales-head', JSON.stringify(data));


                //Hier die Funktion, für das Filtern der Daten und dann auf die Positionen setzen
                const MAPPEDPOS =  MapArticleData();

                if (MAPPEDPOS) {

                    localStorage.setItem('at-position-data', JSON.stringify(MAPPEDPOS));

                }

                console.log("MAPPEDPOS", MAPPEDPOS);
            }

        } catch (error) {
            console.log(error);
        } finally {
            console.log("Data is loaded");
        }
    }


    //Handle the GET of the ReyBex Positions
    const [ReyBexArticleData, setReyBexArticleData] = useState([]);

    useEffect(() => {
        HandlePositionGet();
        setReyBexArticleData(JSON.parse(localStorage.getItem('rb-position-data-sales-head')));
    }, [])




    //HandleChange for Position
    const handleChange = (e) => {
        const { name, value } = e.target;

        setPositionData({
            ...positionData,
            [name]: value,
        });
    }


    //Is New Position is selected

    const [currentPos, setCurrentPos] = useState();


    useEffect(() => {
    // Funktion, die aufgerufen wird, wenn der Wert in localStorage geändert wird
    const updateCurrentPosition = () => {
        const currentPos = localStorage.getItem('rb-current-position');
        if (currentPos) {
        setCurrentPos(JSON.stringify(currentPos));
        }
    };
    
    // Überwache Änderungen im gleichen Tab (falls du an irgendeiner Stelle setItem aufrufst)
    const originalSetItem = localStorage.setItem;
    localStorage.setItem = function(key, value) {
        originalSetItem.apply(this, arguments);  // Original setItem aufrufen
        if (key === 'rb-current-position') {
        updateCurrentPosition();  // State sofort aktualisieren
        }
    };
    
    // Überwache das 'storage'-Event für Änderungen in anderen Tabs
    const handleStorageChange = (event) => {
        if (event.key === 'rb-current-position') {
        updateCurrentPosition();
        }
    };
    
    // Event-Listener für Änderungen in anderen Tabs
    window.addEventListener('storage', handleStorageChange);
    
    // Initial einmal die aktuelle Position holen
    updateCurrentPosition();
    
    // Cleanup: Event-Listener und überschriebenes setItem zurücksetzen
    return () => {
        window.removeEventListener('storage', handleStorageChange);
        localStorage.setItem = originalSetItem;  // setItem zurücksetzen
    };
    }, []); // Leeres Dependency Array -> nur einmal beim Mounten ausführen
    

    //Position Data Handling! 

    const [positionData, setPositionData] = useState({
        wareWarenbezeichnung: '',
        statistischerWert: '',
        artdesGeschafts: '',
        ausfuhrLand: '',
        referenznummerUCR: '',
        registriernummerextern: '',
        ursprungsland: '',
        ursprungsVersendungsregion: '',
        warecusnummer: '',
        wareWarennummerKN8: '',
        wareRohmasse: '',
        wareEigenmasse: '',
        mengeinbesondererMabeinheit: '',
        beforderungskostenZahlungsart: '',
        bewilligung: [
          {
            art: '',
            referenznummer: '',
            bewilligungsinhaber: '',
          },
        ],
    });



    useEffect(() => {
    //Handle the first Position!
    const setFirstPosition = () => {

        if (!currentPos) {
            const Positions = JSON.parse(localStorage.getItem('rb-position-data-sales-head'));

            localStorage.setItem('rb-current-position', JSON.stringify(Positions[0]));
        }
    }

    setFirstPosition();

    if (currentPos) {

        const CurrentPos = JSON.parse(localStorage.getItem('rb-current-position'));

        setPositionData({
            wareWarenbezeichnung: CurrentPos.material.name,
            wareEigenmasse: CurrentPos.netWeight,
            wareRohmasse: CurrentPos.grossWeight,
        })
    }

    }, [currentPos])


    // Funktion, die das Feld in der Position aktualisiert
    const handlePositionChange = (e) => {
        const { name, value } = e.target;
        setPositionData({
            ...positionData,
            [name]: value,
        });
    };



    return ( 
        <>
        {IsDataValid ? "" : (
        <FullPageLoading
        loadingtitle="Daten werden geladen"
        />
        )}
        <div className="wrapper">
            <MainHeader/>
        </div>
        <div className="sectionwrapper">
            <div className="wrapper">
                <HeadLine headline="Anmeldung zur Ausfuhr"/>
                <div className="toolbarwrapper">
                    <div className="button_wrapper_left">
                        {positionSection === false ? "" : (
                            <>
                            <button className='beo_btn_organge' onClick={handlePosition}>
                                <span>Zurück</span>
                            </button>
                            <button className='beo_btn_organge' onClick={sendAtlasData}>
                                <span>An Atlas übermitteln</span>
                                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#5f6368">
                                    <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z"/>
                                </svg>
                            </button>
                            </>
                        )}

                        {positionSection === false ? (
                            <>
                            <button className='beo_btn_organge' onClick={handlePosition}>
                                <span>Positionsdaten</span>
                                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#5f6368">
                                    <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z"/>
                                </svg>
                            </button>
                            </>
                        ) : ""}
                        <button className='beo_btn_transparent' onClick={() => goto("/pendingdeliverynotes")}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#5f6368">
                                <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
                            </svg>
                            <span>Ausfuhranmeldung abbrechen</span>
                        </button>
                    </div>

                    {location.pathname.includes("pendingdeliverynotes") ? (
                        <>
                        <div className="search_field_wapper">
                            <input type="text" placeholder='Suchen...' onChange={handleChange}/>
                        </div>
                        </>
                        ) : (
                        <>
                        <span></span>
                        </>)}
                        </div>
            </div>

            <div className="contentwrapper">

                {positionSection === false ? (
                <SectionNav 
                currentSection={SectionActive}
                setSection={setSectionActive}
                />
                ) : (
                <SectionNavPos
                currentSection={SectionActive}
                setSection={setSectionActive}
                />
                )}
                                            
                {positionSection === false ? (
                <div className="CreateAtlasMessage_Mainwrapper">
                    {SectionActive === "Kopfdaten" ? (<HeaderInformation/>) : ""}
                    {SectionActive === "Bewilligung" ? (<ApprovalSection/>) : ""}
                    {SectionActive === "Lieferketten-Beteiligter" ? (<SupplyChainParticipant/>) : ""}
                    {SectionActive === "Wiedereinfuhr" ? (<ReimportSection/>) : ""}
                </div>
                ) : (
                <div className="CreateAtlasMessage_Mainwrapper">
                    <div className="beo_mainpage_grid">
                    {currentPos && (
                        <>
                        
                    
                        <div className="header_left_column_wrapper">
                            <h2>Allgemeine Informationen für die Ausfuhranmeldung</h2>
                                <PositionSelect
                                    fieldname="Positionsnummer"
                                    index={100}
                                    zIndex={100}
                                    onChange={handleChange}
                                    options={ReyBexArticleData}
                                    zindex="1001"
                                /> 
                            <div className="form_wrapper">
                                <TextDropDownButton
                                fieldname="Warennummer"
                                index={1}
                                zIndex="1000"
                                />      
                                <TextArea
                                fieldname="Warenbezeichnung"
                                maxLength={512}
                                value={positionData.wareWarenbezeichnung}
                                name={"wareWarenbezeichnung"}
                                onChange={handlePositionChange}
                                />
                                <Inputfield
                                fieldname="Warennummer HS"
                                value="1234"
                                styling="disabled"
                                type="text"
                                readOnly={true}
                                mandatory="none"
                                />
                                <Inputfield
                                fieldname="Eigenmasse (in Kg)"
                                mandatory="mandatory"
                                name={"wareEigenmasse"}
                                value={positionData.wareEigenmasse}
                                onChange={handlePositionChange}
                                />
                                <Inputfield
                                fieldname="Rohmasse (in Kg)"
                                mandatory="mandatory"
                                name={"wareRohmasse"}
                                value={positionData.wareRohmasse}
                                onChange={handlePositionChange}
                                />
                                <Inputfield
                                fieldname="Menge in besonderer Maßeinheit"
                                value="1234"
                                styling="disabled"
                                type="text"
                                readOnly={true}
                                mandatory="none"
                                />
                                <Inputfield
                                fieldname="Statistischer Wert"
                                value=""
                                type="text"
                                />
                                <TextDropDownButton
                                fieldname="Urspungsland"
                                index={2}
                                zindex="1001"
                                options={
                                  CustomerOptions.getOptionsForCompany
                                    .find(item => item.type === "128")?.options || []
                                } 
                                onChange={handlePositionChange}
                                name="ursprungsland"
                                />              
                                <TextDropDownButton
                                fieldname="Ursprung Versenderregion"
                                zindex="1000"
                                onChange={handlePositionChange}
                                name="ursprungsVersendungsregion"
                                index={3}
                                zIndex="998"
                                options={
                                  CustomerOptions.getOptionsForCompany
                                    .find(item => item.type === "118")?.options || []
                                }
                                />              
                                <TextDropDownButton
                                fieldname="Beförderungskosten (Zahlungsart)"
                                index={4}
                                zindex="999"
                                options={
                                    CustomerOptions.getOptionsForCompany
                                      .find(item => item.type === "137")?.options || []
                                }
                                onChange={handlePositionChange}
                                name="beforderungskostenZahlungsart"
                                />          
                                <Inputfield
                                fieldname="Ware / Referenznummer der Sendung (UCR)"
                                value="1234"
                                styling="disabled"
                                type="text"
                                readOnly={true}
                                mandatory="none"
                                />    
                                <Inputfield
                                fieldname="Ausfuhrland"
                                value="1234"
                                styling="disabled"
                                type="text"
                                readOnly={true}
                                mandatory="none"
                                />
                              
                            </div>
                          
                        </div>
                        <div className="header_right_column_wrapper">
                            <h2>Beteiligtenkonstelation</h2>
                            <div className="form_wrapper">
                                <h2>Verfahren</h2>
                                <TextDropDownButton
                                fieldname="Beantragtes Verfahren"
                                onChange={handleChange}
                                index={5}
                                zindex="1001"
                                options={
                                  CustomerOptions.getOptionsForCompany
                                    .find(item => item.type === "115")?.options || []
                                }
                                />
                                <TextDropDownButton
                                fieldname="Vorhergehendes Verfahren"
                                onChange={handleChange}
                                zindex="1000"
                                options={
                                  CustomerOptions.getOptionsForCompany
                                    .find(item => item.type === "116")?.options || []
                                }
                                index={6}
                                />
                                <TextDropDownButton
                                fieldname="Zusätzliches Verfahren"
                                zindex="999"
                                />
                            </div>
                            <div className="form_wrapper">
                                <h2>Geschäftsvorgang</h2>
                                <TextDropDownButton
                                fieldname="Art des Geschäfts"
                                index={7}
                                zindex="998"
                                options={
                                  CustomerOptions.getOptionsForCompany
                                    .find(item => item.type === "105")?.options || []
                                }
                                onChange={handlePositionChange}
                                name="artdesGeschafts"
                                />
                            </div>
                            <div className="form_wrapper">
                                <h2>Passive Veredelung</h2>
                                <DropDown
                                fieldname="Standardaustausch/Ersatzwarenverkehr"
                                options={["Ja", "Nein"]}
                                displayedName="description"
                                longText="description2"
                                maxItems={5}
                                />
                                 <Inputfield
                                fieldname="Datum der Wiedereinfuhr"
                                value="1234"
                                styling="disabled"
                                type="date"
                                readOnly={true}
                                mandatory="none"
                                />
                            </div>
                            <div className="accordion">
                                <AccordionForm
                                headline="Empfänger"
                                />
                                <AccordionForm
                                headline="Versender"
                                />
                            </div>
                        </div>
                        </>
                    )}
                    </div>
                </div>
                )}
            </div>
        </div>
        </>
     );
}

export default CreateAtlasMessage;


