import React, { useState, useEffect } from 'react';

//importing the css
import '../../css/input fields/TextDropDownButton.css';


function TextDropDownButton({
    options, 
    value, 
    onChange,
    countryOfShipping, 
    ...props
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(""); // separate Anzeige für Code + Beschreibung
    const [isDefaultSet, setIsDefaultSet] = useState(false); // Trackt, ob der Defaultwert bereits gesetzt wurde
    const [erpOption, setErpOption] = useState(''); // Trackt, ob die Option aus dem ERP stammt

    const handleToggle = () => setIsOpen(!isOpen);

    const handleSelect = (description, code) => {
        // Aktualisiere die Anzeige mit Code und Beschreibung (für den Benutzer sichtbar)
        setSelectedValue(`${code} - ${description}`);
        // Übergebe nur den Code an die onChange Funktion (für die Logik)
        onChange({ target: { name: props.name, value: code } });
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (e.target.closest('.textdropdownwrapper') !== null) {
                return;
            } else {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            window.addEventListener('click', handleClickOutside);
        } else {
            window.removeEventListener('click', handleClickOutside);
        }

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen]);

    useEffect(() => {
        // Option automatisch basierend auf dem ausgewählten Wert initialisieren
        if (!isDefaultSet) {
            const selectedOption = options?.find(option => option.isSelected === true);

            if (selectedOption) {
                // Setze den angezeigten Wert auf "Code - Beschreibung"
                setSelectedValue(`${selectedOption.code} - ${selectedOption.description}`);
                setIsDefaultSet(true); // Verhindert erneutes Setzen bei weiteren Updates
            } else {
                // Falls nichts ausgewählt ist, zeige den reinen Wert an
                setSelectedValue(value);
            }
        } else {
            // Aktualisiere die Anzeige nur, wenn value sich ändert (nach der Benutzerauswahl)
            const selectedOption = options?.find(option => option.code === value);
            if (selectedOption) {
                setSelectedValue(`${selectedOption.code} - ${selectedOption.description}`);
            } else {
                setSelectedValue(value);
            }
        }

        if (!erpOption) {
            
            const AtlasStructure = JSON.parse(localStorage.getItem('at-header-data'));

            if (AtlasStructure) {
                const selectedOption = options?.find(option => option.code === AtlasStructure[props.name]);
                if (selectedOption) {
                    setSelectedValue(`${selectedOption.code} - ${selectedOption.description}`);
                    setErpOption(AtlasStructure[props.name]);
                }
            }

        }

    }, [value, options, isDefaultSet, erpOption, props.name]); 


    return ( 
        <>
        <div className="textdropdownwrapper" style={{zIndex: props.zindex}}>
            <div className="dropdownnamewrapper">
                <span>{props.fieldname}</span>
            </div>
            <div className="textdropdown_btn_wrapper">
                <div className="textdropdownrowwrapper">
                    <button onClick={handleToggle}>
                        <input 
                        type="text" 
                        placeholder='Bitte auswählen...'
                        value={selectedValue}
                        onChange={onChange}
                        readOnly
                        />
                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#5f6368">
                            <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z"/>
                        </svg>
                    </button>

                    <div className="addbutton">
                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#5f6368">
                            <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/>
                        </svg>
                    </div>
                </div>

                <div className={`textdropdown_content_box ${isOpen ? 'dropdown_visible' : ''}`}>
                    <ul>
                    {options?.map((option) => (
                        <li key={option.id} onClick={() => handleSelect(option.description, option.code)}>
                            <span>{option.code}, {option.description}</span>
                        </li>
                    ))}
                    </ul>
                </div>
            </div>
        </div>
        </>
    );
}

export default TextDropDownButton;
