import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

//importing the css
import '../../css/input fields/DropDown.css';

function DropDown({ options, ...props}) {

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const maxItems = 100;


    const defaultOptions = [{
        value: 1,
        label: 'Option 1'
    }, {
        value: 2,
        label: 'Option 2'
    }, {
        value: 3,
        label: 'Option 3'
    }];

    const handleToggle = () => setIsOpen(!isOpen);

    const handleSelect = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (e.target.closest('.dropdown_btn_wrapper') !== null) {
                return;
            } else {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            window.addEventListener('click', handleClickOutside);
        } else {
            window.removeEventListener('click', handleClickOutside);
        }

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen]);

    
    return ( 
        <>
        <div className="dropdownwrapper">
                <div className="dropdownnamewrapper">
                    <span>{props.fieldname}</span>
                </div>
                <div className="dropdown_btn_wrapper">
                    <button onClick={handleToggle}>
                    {selectedOption ? `${selectedOption[props.displayedName]}` : 'Bitte auswählen...'}
                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#5f6368">
                            <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z"/>
                        </svg>
                    </button>


                        <div className={`dropdown_content_box ${isOpen ? 'dropdown_visible' : ''}`}>
                        <input type="text" placeholder='Suchen...'/>
                        <ul>
                            {options.length === 0 ? (
                                defaultOptions.map((option) => (
                                    <li key={option.value}>{option.label}</li>
                                ))
                            ) : (
                                options.slice(0, maxItems).map((option) => (
                                    <li key={option.value} onClick={() => handleSelect(option)}>{`${option[props.displayedName]}`}</li>
                                ))
                            )}
                        </ul>

                    </div>
                
                </div>
        </div>
        </>
     );
}

export default DropDown;