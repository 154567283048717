import React from 'react';

//importing the CSS
import '../../css/toolbar/ToolBar.css';
import { useLocation } from 'react-router-dom';

function ToolBar({ onInputChange }, props) {


    //getting the search informations: 
    const handleChange = (event) => {
        onInputChange(event.target.value);
    };


    const location = useLocation();



    return ( 
        <>
        <div className="toolbarwrapper">
            <div className="button_wrapper_left">
                <button className='beo_btn_organge'>
                    <span>Positionsdaten</span>
                    <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#5f6368">
                        <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z"/>
                    </svg>
                </button>
                <button className='beo_btn_transparent' onClick={props.onClickCreate}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#5f6368">
                        <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z"/>
                    </svg>
                    <span>Neue Ausfuhranmeldung</span>
                </button>
            </div>

            {location.pathname.includes("pendingdeliverynotes") ? (
                <>
                <div className="search_field_wapper">
                    <input type="text" placeholder='Suchen...' onChange={handleChange}/>
                </div>
                </>
            ) : (
            <>

            </>)}
        </div>
        </>
     );
}

export default ToolBar;