import React, { useEffect, useState } from 'react';

//importing the components. 

import MainHeader from '../../components/header/MainHeader';
import HeadLine from '../../components/toolbar/HeadLine';   
import ToolBar from '../../components/toolbar/ToolBar';

//loading 
import FullPageLoading from '../../components/Loadinganimation/FullPageLoading';


//importing the CSS
import "../../css/pages/AtlasMessages.css"

//importing the base
import base from '../../apis/base.json';

function AtlasMessages() {

    //here i am saving the Atlas Messages
    const [atlasMessages, setAtlasMessages] = useState();

    // Get all ATLAS - MESSAGES
    const GetAddressData = async () => {

    // Definition of the GraphQL Request
    const RequestData = {
    
            query: "query ($filter: StatusFilterDTO,$page: GraphqlPageRequestDTO ) { exportsStatus(filter:$filter,page: $page) {statusData { nachrichtennummer datum mrnlrn empfanger pdf{relPdf notPdf}} totalPages totalRows} }",
            variables: {
            filter: {
            "nachrichtennummer": "",
            "datum": "",
            "mrnlrn": "",
            "empfanger": ""
            },
            "page": {
            "startLimit": 0,
            "endLimit": 50
            }
            }
           
    };

    try {
      // Await the fetch response
      const response = await fetch(`${base.base_beo}GetData/ausfuhr/address`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "bearerToken": localStorage.getItem("Atoken"),
          "token": localStorage.getItem("token"),
        },
        body: JSON.stringify(RequestData),
      });

      // Check if the response is ok
      if (response.ok) {
        const responseData = await response.json();
        setAtlasMessages(responseData.exportsStatus.statusData);
        console.log("Address Data", responseData);
      } else {
        console.error("HTTP-Error: " + response.status);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };


  useEffect(() => {
    GetAddressData();
  }, [])


  console.log("Address Data saved", atlasMessages);


    return ( 
        <>
        <div className="wrapper">
            <MainHeader/>
        </div>
        <div className="sectionwrapper">
            <div className="wrapper">
                <HeadLine headline="Ausfuhrstatus"/>
                <ToolBar/>
            </div>

            {atlasMessages? (<>
            <div className="pendingdeliveries_wrapper">
                <div className="tablebox">
                    <table>
                        <thead>
                            <tr>
                                <th>Nr.</th>
                                <th>Empfänger</th>
                                <th>LRN</th>
                                <th>MRN</th>
                                <th>Gesendet am.</th>
                                <th>Bearbeiter</th>
                                <th>Überlassung</th>
                                <th>Mitteilung zur Ausfuhr</th>
                                <th>Status</th>                                
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {atlasMessages && atlasMessages.map((message, index) => (
                                <>
                                    <tr>
                                        <td>1</td>
                                        <td>{message.empfanger}</td>
                                        <td>{message.mrnlrn}</td>
                                        <td>24DE586601154913B8</td>
                                        <td>{message.datum}</td>
                                        <td>Felix Burkhard</td>
                                        <td>
                                            <div className="abd_available">
                                                <div className="download_btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#5f6368">
                                                        <path d="m480-280 160-160-56-56-64 62v-166h-80v166l-64-62-56 56 160 160ZM240-80q-33 0-56.5-23.5T160-160v-480l240-240h320q33 0 56.5 23.5T800-800v640q0 33-23.5 56.5T720-80H240Zm0-80h480v-640H434L240-606v446Zm0 0h480-480Z"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="abd_available">
                                                <div className="download_btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#5f6368">
                                                        <path d="m480-280 160-160-56-56-64 62v-166h-80v166l-64-62-56 56 160 160ZM240-80q-33 0-56.5-23.5T160-160v-480l240-240h320q33 0 56.5 23.5T800-800v640q0 33-23.5 56.5T720-80H240Zm0-80h480v-640H434L240-606v446Zm0 0h480-480Z"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="Status_Button">
                                                <div className="icon_round">

                                                </div>
                                                <span>
                                                    Neue Nachricht
                                                </span>
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            </>) : (<>
            <FullPageLoading loadingtitle="Hole Atlas-Nachrichten"/>
            </>)}
        </div>
        </>
     );
}

export default AtlasMessages;