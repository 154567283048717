import base from '../base.json';

export const AtlasAuth = async (Username, Password, token, goTo) => {

    localStorage.setItem('token', token)

    const authUser = btoa(`${Username}:${Password}`);


    console.log(authUser)
    console.log(token)


    try {
        const data = await fetch(`${base.base_beo}authenticate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${authUser}`
            }
        })

        console.log(authUser)

        const response = await data.json()
        console.log(response)

        localStorage.setItem('Atoken', response.accessToken)
        goTo("/pendingdeliverynotes")

    } catch (error) {
        console.error(error)
    }
}