import React from 'react';
import '../../css/input fields/InputField.css';
import { onFocusSelectValue } from '../../utils/onFocusSelectValue';

function Inputfield({
    fieldname,
    name,
    type,
    placeholder,
    styling,
    readOnly,
    value,
    onChange,
    onFocus = onFocusSelectValue, // Standardmäßig `onFocusSelectValue`, kann aber überschrieben werden
}) {
    return (
        <div className="inputwrapper">
            <div className="fieldnamewrapper">
                <span>{fieldname}</span>
            </div>
            <input
                name={name}
                type={type}
                placeholder={placeholder}
                className={styling}
                readOnly={readOnly}
                value={value}
                onChange={onChange}
                onFocus={onFocus}
            />
        </div>
    );
}

export default Inputfield;
