import base from '../../base.json';
import { toast } from 'react-toastify';


export const sendAtlasData = async () => {

    const Positions = JSON.parse(localStorage.getItem('at-position-data')) || [];
    const HeaderData = JSON.parse(localStorage.getItem('at-header-data')) || {};

    console.log(Positions, HeaderData)

    const AtlasData = {
        dataIdentifier: "EXPDAT",
        kundenNumber: "",
        mandantId: "FTAM",
        module: {
            ausfuhr: {
                data: true,
                email: "",
                autosend: false,
            }
        },
        messageData: {
            ausfuhr: {
                expdat: [{
                    kopf: {
                        eoriNiederlassungsnummer: "",
                        dienststellennummer: "",
                        bezugsnummer: HeaderData.bezugsnummer || "",
                        artderAnmeldung: HeaderData.artderAnmeldung || "",
                        artderAusfuhranmeldung: HeaderData.artderAusfuhranmeldung || "",
                        beteiligtenKonstellation: HeaderData.beteiligtenKonstellation || "",
                        zeitpunktderAnmeldung: HeaderData.zeitpunktderAnmeldung || "",
                        massgeblichesDatum: HeaderData.massgeblichesDatum || "",
                        kopfDatumdesAusgangs: HeaderData.kopfDatumdesAusgangs || "",
                        zeitpunktDerGestellung: HeaderData.zeitpunktDerGestellung || "",
                        zeitpunktdesEndesderLadetatigkeit: HeaderData.zeitpunktdesEndesderLadetatigkeit || "",
                        sicherheit: HeaderData.sicherheit || "",
                        besondereUmstande: HeaderData.besondereUmstande || "",
                        inRechnunggestellterGesamtbetrag: HeaderData.inRechnunggestellterGesamtbetrag || "",
                        rechnungswahrung: "",
                        bewilligung: [
                            {
                                art: "",
                                referenznummer: ""
                            }
                        ],
                        gestellungszollstelle: "",
                        ausfuhrzollstelleDienststellennummer: "",
                        ausfuhrZollstelleFurDieErganzende: "",
                        vorgeseheneAusgangszollstelleDienststellennummer: "",
                        tatsachlicheAusgangszollstelleDienststellennummer: "",
                        aussenwirtschaftsrechtlicherAusfuhrer: {
                            tin: "",
                            niederlassungsNummer: "",
                            name: "",
                            strasse: "",
                            plz: "",
                            ort: "",
                            land: ""
                        },
                        ausfuhrer: {
                            tin: "",
                            niederlassungsNummer: "",
                            name: "",
                            strasse: "",
                            plz: "",
                            ort: "",
                            land: "",
                        },
                        anmelder: {
                            tin: "",
                            niederlassungsNummer: "",
                            name: "",
                            strasse: "",
                            plz: "",
                            ort: "",
                            land: "",
                            phone: "",
                            ansprechName: "",
                            ansprechEmail: ""
                        },
                        vertreter: {
                            tin: "",
                            niederlassungsNummer: "",
                            phone: "",
                            ansprechName: "",
                            ansprechEmail: ""
                        },
                        subunternehmer: {
                            tin: "",
                            niederlassungsNummer: "",
                            name: "",
                            strasse: "",
                            plz: "",
                            ort: "",
                            land: ""
                        },
                        artdesGeschafts: "",
                        ausfuhrland: "",
                        bestimmungsLand: HeaderData.bestimmungsLand || "",
                        lieferkettenBeteiligter: [
                            {
                                funktion: "",
                                identifikationsnummer: ""
                            }
                        ],
                        lieferbedingungIncotermCode: "",
                        lieferbedingungUnLocode: "",
                        lieferbedingungOrt: "",
                        lieferbedingungLand: "",
                        lieferbedingungText: "",
                        wiedereinfuhr: [
                            {
                                wiedereinfuhrLand: ""
                            }
                        ],
                        namlichkeitsmittel: [
                            {
                                namlichkeitsmitteArt: "",
                                namlichkeitsmittelTextlicheBeschreibung: ""
                            }
                        ],
                        erzeugnis: [
                            {
                                erzeugnisWarenbezeichnung: "",
                                unterpositionDesHarmonisiertenSystems: "",
                                unterpositionDerKombiniertenNomenklatur: ""
                            }
                        ],
                        vorpapier: [
                            {
                                art: "",
                                qualifikator: "",
                                referenznummer: ""
                            }
                        ],
                        unterlage: [
                            {
                                art: "",
                                qualifikator: "",
                                referenznummer: "",
                                zeilenPositionsnummer: "",
                                name: "",
                                datumDerAusstellung: "",
                                gultigkeitsdatum: ""
                            }
                        ],
                        sonstigerVerweis: [
                            {
                                art: "",
                                qualifikator: "",
                                referenznummer: ""
                            }
                        ],
                        zusatzlicherInformation: [
                            {
                                code: "",
                                text: ""
                            }
                        ],
                        containerIndikator: "",
                        inlandischerVerkehrszweig: "",
                        verkehrszweigAnDerGrenze: "",
                        gesamtRohmasse: HeaderData.gesamtRohmasse || "",
                        referenznummerUCR: HeaderData.bezugsnummer || "",
                        registriernummerextern: "",
                        beforderer: {
                            tin: "",
                            niederlassungsNummer: ""
                        },
                        versender: {
                            tin: "",
                            niederlassungsNummer: "",
                            name: "",
                            strasse: "",
                            plz: "",
                            ort: "",
                            land: ""
                        },
                        empfanger: {
                            tin: "",
                            niederlassungsNummer: "",
                            name: "",
                            strasse: "",
                            plz: "",
                            ort: "",
                            land: ""
                        },
                        transportausrustung: [
                            {
                                containernummer: "",
                                anzahlderVerschlusse: "",
                                verschluss: [
                                    {
                                        verschlusskennzeichen: ""
                                    }
                                ],
                                warenpositionsverweis: [
                                    {
                                        positionsnummer: ""
                                    }
                                ]
                            }
                        ],
                        warenortArtdesOrtes: "",
                        warenortArtDerOrtsbestimmung: "",
                        warenortBewilligungsnummer: "",
                        warenortZusatzlicheKennung: "",
                        warenortUnLocode: "",
                        warenortGnssBreite: "",
                        warenortGnssLang: "",
                        warenort: {
                            name: "",
                            strasse: "",
                            plz: "",
                            ort: "",
                            land: "",
                            phone: "",
                            ansprechName: "",
                            ansprechEmail: ""
                        },
                        beforderungsmittelBeimAbgang: [
                            {
                                artderIdentifikation: "",
                                kennzeichen: "",
                                staatszugehorigkeit: ""
                            }
                        ],
                        beforderungsroute: {
                            ausgewahlteLander: [""]
                        },
                        beforderungsmittelderGrenzeArt: "",
                        beforderungsmittelderGrenzeKennzeichen: "",
                        beforderungsmittelderGrenzeStaatszugehorigkeit: "",
                        transportdokument: [
                            {
                                art: "",
                                qualifikator: "",
                                referenznummer: ""
                            }
                        ],
                        beforderungskostenZahlungsart: ""
                    },
                    //Start the Position
    
                    position: 
                        Positions.map((pos) => ({
                            warePositionsnummer: "",
                            statistischerWert: "0",
                            artdesGeschafts: "11",
                            ausfuhrLand: "DE",
                            referenznummerUCR: "",
                            registriernummerextern: "",
                            bewilligung: [
                                {
                                    art: "",
                                    referenznummer: "",
                                    bewilligungsinhaber: ""
                                }
                            ],
                            beantragtesVerfahren: "",
                            vorhergehendesVerfahren: "",
                            zusatzlichesVerfahren: "",
                            versender: {
                                tin: "",
                                niederlassungsNummer: "",
                                name: "",
                                strasse: "",
                                plz: "",
                                ort: "",
                                land: ""
                            },
                            wareEmpfanger: {
                                tin: "",
                                niederlassungsNummer: "",
                                name: "",
                                strasse: "",
                                plz: "",
                                ort: "",
                                land: ""
                            },
                            lieferkettenBeteiligter: [
                                {
                                    funktion: "",
                                    identifikationsnummer: ""
                                }
                            ],
                            ursprungsland: "",
                            ursprungsVersendungsregion: "",
                            wareWarenbezeichnung: pos.wareWarenbezeichnung || "",
                            warecusnummer: "",
                            wareWarennummerKN8: "",
                            tariczusatzcode: [
                                {
                                    tariczusatzcode: ""
                                }
                            ],
                            gefahrgut: [
                                {
                                    unnummer: ""
                                }
                            ],
                            wareRohmasse: pos.wareRohmasse || "",
                            wareEigenmasse: pos.wareEigenmasse || "",
                            mengeinbesondererMabeinheit: "",
                            verpackung: [
                                {
                                    artderVerpackung: "",
                                    anzahlderPackstucke: "",
                                    versandzeichen: "",
                                    packstuckverweisPositionsnummer: ""
                                }
                            ],
                            vorpapier: [
                                {
                                    vorpapierArt: "",
                                    vorpapierQualifikator: "",
                                    vorpapierReferenznummer: "",
                                    vorpapierPositionsnummer: "",
                                    vorpapierMabeinhet: "",
                                    vorpapierMenge: "",
                                    vorpapierZusatzlicheAngaben: ""
                                }
                            ],
                            unterlage: [
                                {
                                    art: "",
                                    qualifikator: "",
                                    referenznummer: "",
                                    zeilenPositionsnummer: "",
                                    zusatzlicheAngaben: "",
                                    detail: "",
                                    namederausstellendenBehorde: "",
                                    datumderAusstellung: "",
                                    gultigkeitsdatum: "",
                                    mabeinheit: "",
                                    erganzendeMabeinheit: "",
                                    menge: "",
                                    wahrung: "",
                                    betrag: ""
                                }
                            ],
                            sonstigerVerweis: [
                                {
                                    art: "",
                                    qualifikator: "",
                                    referenznummer: "",
                                    detail: "",
                                    wahrung: "",
                                    betrag: ""
                                }
                            ],
                            zusatzlicheInformationlist: [
                                {
                                    code: "",
                                    text: ""
                                }
                            ],
                            beforderungskostenZahlungsart: "",
                            standardaustauschErsatzwarenverkehr: "",
                            datumderWiedereinfuhr: "",
                            zolllager: [
                                {
                                    zuganginatlas: "",
                                    mrn: "",
                                    registriernummer: "",
                                    positionsnummer: "",
                                    ublichebehandlung: "",
                                    zusatzlicheangaben: "",
                                    warennummer: "",
                                    abgangsmengeMabeinheit: "",
                                    abgangsmengeQualifikator: "",
                                    abgangsmengeMenge: "",
                                    handelsmengeMabeinheit: "",
                                    handelsmengeQualifikator: "",
                                    handelsmengeMenge: ""
                                }
                            ],
                            verfahrensubergangAv: [
                                {
                                    positionAvZugangInAtlas: "",
                                    warenPositionMrn: "",
                                    positionAvRegistriernummer: "",
                                    positionAvPositionsnummer: "",
                                    positionAvWarenbezogeneAngaben: ""
                                }
                            ],
                            zolllagerLrn: "",
                            zolllagerBewilligungArt: "",
                            zolllagerBewilligungReferenznummer: "",
                            vereinfachtErteilteBewilligung: "",
                            bewilligungArt: "",
                            bewilligungReferenznummer: "",
                            uberwachungsZollstelleReferenznummer: ""
                        }))
    
                    }
                ]
            }
        }
    };
    

    console.log('Data to send:', JSON.stringify(AtlasData, null, 2));

    //getting the token

    const Atoken = localStorage.getItem('Atoken');
    const token = localStorage.getItem('token');

    try {
        const response = await fetch(`${base.base_beo}PutData`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'bearerToken': Atoken,
            'token': token
        },
        body: JSON.stringify(AtlasData)
        });

        if (!response.ok) {
            toast.error('Atlas data was not sent', {
                position: "bottom-right",
            });
            throw new Error('Network response was not ok');
        }


            toast.success('Ausfuhranmeldung wurde erfolgreich an Atlas Ausfuhr übermittelt', {
                position: "bottom-right",
            });

    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
};
