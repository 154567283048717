import React from 'react';
import ReactDOM from 'react-dom/client';

//imorting Provider
import { ToastContainer } from 'react-toastify';

//Importing the CSS
import './css/global.css';
import 'react-toastify/dist/ReactToastify.css';

//Importing the Router moduls
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//Setup pages
import ReyBexSetup from './pages/Setup/SetUpReybex';
import BeoAtlasSetup from './pages/Setup/SetUpAtlas';

//Importing the pages
import CreateAtlasMessage from './pages/Userpages/CreateAtlasMessage';

import PendingDeliveries from './pages/Userpages/PendingDeliveries';
import AtlasMessages from './pages/Userpages/ShowAtlasMessages';


//importing the reduxProvider
import { Provider } from 'react-redux';
import { store } from './apis/REDUX/store';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
        <Router>
            <Routes>
              <Route path="/" element={<ReyBexSetup/>} />
              <Route path="/beoatlassetup" element={<BeoAtlasSetup/>} />
              <Route path="/kopfdatenatlasausfuhr/:delnote/:reybexlsid" element={<CreateAtlasMessage/>} />
              <Route path="/pendingdeliverynotes" element={<PendingDeliveries/>} />
              <Route path="/atlaststus" element={<AtlasMessages/>} />
            </Routes>
          </Router>
    </Provider>
    <ToastContainer />
  </React.StrictMode>
);
