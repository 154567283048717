

export function MapArticleData() {

    const ReyBeyData = JSON.parse(localStorage.getItem('rb-position-data-sales-head'));

    const ATLASPOSNEST = {
        wareWarenbezeichnung: '',
        statistischerWert: '',
        artdesGeschafts: '',
        ausfuhrLand: '',
        referenznummerUCR: '',
        registriernummerextern: '',
        ursprungsland: '',
        ursprungsVersendungsregion: '',
        warecusnummer: '',
        wareWarennummerKN8: '',
        wareRohmasse: '',
        wareEigenmasse: '',
        mengeinbesondererMabeinheit: '',
        beforderungskostenZahlungsart: '',
        verkehrszweigAnDerGrenze: "",
        bewilligung: [
          {
            art: '',
            referenznummer: '',
            bewilligungsinhaber: '',
          },
        ],
    }


    if (ReyBeyData) {
        const mappedData = ReyBeyData.map((article) => {
            return {
                ...ATLASPOSNEST,
                id: article.id,
                wareWarenbezeichnung: article.material.name,
                statistischerWert: "",
                artdesGeschafts: "",
                ausfuhrLand: "",
                referenznummerUCR: "",
                registriernummerextern: "",
                ursprungsland: article.material.originCountry,
                ursprungsVersendungsregion: article.material.originShippingRegion,
                wareWarennummerKN8: article.material.goodsNumberKN8,
                wareRohmasse: article.grossWeight,
                wareEigenmasse: article.netWeight,
                mengeinbesondererMabeinheit: article.material.quantityInSpecialUnit,
                beforderungskostenZahlungsart: article.material.transportCostsPaymentMethod,
                bewilligung: [
                    {
                        art: article.material.approvalType,
                        referenznummer: article.material.referenceNumber,
                        bewilligungsinhaber: article.material.approvalHolder,
                    },
                ],
            }
        });


        return mappedData;
    }



}