import React, { useState } from 'react';

//importing the css
import '../../../../css/section/createatlasmessage/header/ApprovalSection.css';

//importing the components
import Inputfield from '../../../inputfields/InputField';
import TextDropDownButton from '../../../inputfields/TextDropDownButton';
import SectionTableApproval from '../../../datatables/SectionTableApproval';
import { useSelector } from 'react-redux';

function ApprovalSection(props) {



    //ATLAS - CUSTOMER - OPTIONS
  const ApprovalOptions = useSelector((state) => state.CustomerOptions.data);

  const[formData, setFormData] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    console.log(formData);
  };

    return ( 
        <>
        <div className="section_wrapper">
            <h2>Bewilligung</h2>
            <div className="section_input_container">
                <div className="inputcontainer">
                    <Inputfield
                    fieldname="Anzahl der Einträge"
                    styling="disabled"
                    readOnly={true}
                    value="0"
                    />
                    <TextDropDownButton
                    fieldname="Art der Bewilligung"
                    zindex="1000"
                    index={3}
                    onChange={handleChange}
                    options={
                        ApprovalOptions.getOptionsForCompany
                        .find(item => item.type === "139")?.options || []
                    }
                    />
                    <Inputfield
                    fieldname="Referenznummer"
                    readOnly={false}
                    />
                </div>
            </div>
            <div className="section_table_container">
                <SectionTableApproval/>
            </div>
        </div>
        </>
     );
}

export default ApprovalSection;