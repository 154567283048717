import React, { useEffect, useState } from 'react';

//importing the css
import '../../css/forms/AccordionForm.css';

//importing the components
import DropDownButton from '../inputfields/DropDownButton';
import base from '../../apis/base.json'

function AccordionForm(props) {

    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [openAccordionIndex, setOpenAccordionIndex] = useState(false);

    const toggleAccordion = () => {
        setOpenAccordionIndex(!openAccordionIndex);
    }



    const toggleDropdown = (index) => {
        setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    };


    const [addressData, setAddressData] = useState();


// Getting the address information!
  const GetAddressData = async () => {

    // Definition of the GraphQL Request
    const RequestData = {
      query: "query($type: [String]) { getExportsAddress(type: $type) { type userId addresses{ tin niederlassungsNummer name ort land idActivated dateDeactivated ansprechName referenceNum strasse ansprechStellung ansprechTelefax phone ansprechEmail kundenNum plz }} }",
      variables: {
        type: ["01", "02"]
      }
    };

    try {
      // Await the fetch response
      const response = await fetch(`${base.base_beo}GetData/ausfuhr/address`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "bearerToken": localStorage.getItem("Atoken"),
          "token": localStorage.getItem("token"),
        },
        body: JSON.stringify(RequestData),
      });

      // Check if the response is ok
      if (response.ok) {
        const responseData = await response.json();
        setAddressData(responseData.getExportsAddress);
        console.log("Address Data", responseData);
      } else {
        console.error("HTTP-Error: " + response.status);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };


  console.log("Address Data saved", addressData);




    return ( 
        <>
        <div className="accordionwrapper">
            <div className="accordion_container">
                <div className="accordionbutton" onClick={toggleAccordion}>
                    <h2>{props.headline}</h2>
                    {openAccordionIndex ? (<>
                        <span>-</span>
                    </>) : (<>
                        <span>+</span>
                    </>)}
                </div>

                <div className={openAccordionIndex ? "accordioncontent" : "contenthidden"}>
                    <DropDownButton
                    index={1}
                    isOpen={openDropdownIndex === 1}
                    toggleDropdown={toggleDropdown}
                    />

                    <div className="content">
                        <div className="contentgrid">
                            <div className="left">
                                <div className="displayinfowrapper">
                                    <span className='fieldname'>Identifikationsnummer</span>
                                    <span>Test</span>
                                </div>
                                <div className="displayinfowrapper">
                                    <span className='fieldname'>Niederlassungsnummer</span>
                                    <span>Test</span>
                                </div>
                                <div className="displayinfowrapper">
                                    <span className='fieldname'>Name</span>
                                    <span>Test</span>
                                </div>
                                <div className="displayinfowrapper">
                                    <span className='fieldname'>Straße und Hausnummer</span>
                                    <span>Test</span>
                                </div>
                                <div className="displayinfowrapper">
                                    <span className='fieldname'>Postleitzahl</span>
                                    <span>Test</span>
                                </div>
                                <div className="displayinfowrapper">
                                    <span className='fieldname'>Ort</span>
                                    <span>Test</span>
                                </div>
                                <div className="displayinfowrapper">
                                    <span className='fieldname'>Land</span>
                                    <span>Test</span>
                                </div>
                            </div>
                            <div className="right">
                                <div className="displayinfowrapper">
                                    <span className='fieldname'>Kundennummer</span>
                                    <span>Test</span>
                                </div>
                                <div className="displayinfowrapper">
                                    <span className='fieldname'>Angezeigter Name</span>
                                    <span>Test</span>
                                </div>
                                <div className="displayinfowrapper">
                                    <span className='fieldname'>TIN EU</span>
                                    <span>Test</span>
                                </div>
                                <div className="displayinfowrapper">
                                    <span className='fieldname'>Gültig ab: (Datum)</span>
                                    <span>Test</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
     );
}

export default AccordionForm;