// AtlasApiReducer.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import base from '../../base.json';

// Async Thunk für den API-Request
export const fetchAtlasData = createAsyncThunk(
  "api/fetchAtlasData",
  async () => {
    const sendDataGraphQL = {
      query: "query($type: [String]) { getOptionsForCompany(type: $type) { type userid options{ code version description bemerkung description2 description3 description4 description5 isSelected }} } ",
      variables: {
      type: ["104", "105", "106", "108", "110", "111", "113", "114", "115", "116", "118", "122", "123", "124", "125", "128", "129", "133", "137", "151", "152",
        "153", "139"
      ]
      }
    };

    try {
      const response = await fetch(`${base.base_beo}GetData/common/codelist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'bearerToken': localStorage.getItem('Atoken'),
          'token': localStorage.getItem('token')
        },
        body: JSON.stringify(sendDataGraphQL),
      });

      if (!response.ok) {
        throw new Error('Netzwerkantwort war nicht ok');
      }

      const data = await response.json();

      console.log('Atlas Data:', data);

      // Teile die Daten in verschiedene Teile auf
      const CustOptionCurrency = data;

      return { CustOptionCurrency };
    } catch (error) {
      throw Error('Fehler beim Abrufen der Daten: ' + error.message);
    }
  }
);

const CustOptionsReducer = createSlice({
  name: "CustOptionsReducer",
  initialState: {
    data: null,
    status: "idle",
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAtlasData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAtlasData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload.CustOptionCurrency;
      })
      .addCase(fetchAtlasData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  }
});

const optionsDataSlice = createSlice({
  name: "optionsData",
  initialState: {
    data: null,
    status: "idle",
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAtlasData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAtlasData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload.optionsData;
      })
      .addCase(fetchAtlasData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  }
});

export const CustomerOptions = CustOptionsReducer.reducer;
export const optionsDataReducer = optionsDataSlice.reducer;
