// HeaderInformation.js

import React, { useEffect, useState } from 'react';
import '../../../../css/section/createatlasmessage/header/HeaderInformation.css';
import Inputfield from '../../../../components/inputfields/InputField';
import AccordionForm from '../../../forms/AccordionForm';
import TextDropDownButton from '../../../inputfields/TextDropDownButton';
import base from '../../../../apis/base.json';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAtlasData } from '../../../../apis/REDUX/reducer/AtlasApiReducer';

import { MapLsOnHeader } from '../../../../utils/Filter/ReyBeyArticlesInLS/ReyBexLsMapping';


function HeaderInformation() {
  const { delnote } = useParams();
  const [reybexData, setReybexData] = useState();


  const dispatch = useDispatch();

  //ATLAS - CUSTOMER - OPTIONS
  const CustomerOptions = useSelector((state) => state.CustomerOptions.data);

  useEffect(() => {
    dispatch(fetchAtlasData());
  }, [dispatch]);

  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    
  const toggleDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };

    //Handle the session creation for the Atlas Message
    const [AtlasHeaderData, setAtlasHeaderData] = useState(() => {
      const savedData = localStorage.getItem('at-header-data');
      return savedData ? JSON.parse(savedData) : {
          DateCreated: "",
          ausfuhrland: "",
          ausfuhrzollstelleDienststellennummer: "",
          beforderungsmittelImInlandVerkehrszweig: "",
          beforderungsmittelderGrenzeArt: "",
          beforderungsmittelderGrenzeKennzeichen: "",
          beforderungsmittelderGrenzeStaatszugehorigkeit: "",
          beforderungsmittelderGrenzeVerkehrszweig: "",
          besondererTatbestandKennzeichen: "",
          bestimmungsLand: "",
          beteiligtenKonstellation: "",
          bewilligungZugelassenerAusfuhrer: "",
          bezugsnummer: "",
          container: "",
          gesamtRohmasse: 0,
          geschaftsvorgangArt: "",
          geschaftsvorgangRechnungspreis: 0,
          geschaftsvorgangWahrung: "",
          kennnummerderSendung: "",
          lieferbedingungIncotermCode: "",
          lieferbedingungLand: "",
          lieferbedingungOrt: "",
          lieferbedingungTextTeil: "",
          lrn: "",
          sicherheit: "",
          vorgeseheneAusgangszollstelleDienststellennummer: "",
          warenortLadeortCode: "",
          verkehrszweigAnDerGrenze: ""
      };
  });

  useEffect(() => {
    const fetchReyBexData = async (e) => {
      const response = await fetch(`${base.base_reybex}domains/salesHead?docNumber=${delnote}`,{
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Basic ${localStorage.getItem("Rtoken")}`
        }
      });

      if (response.ok){
        const responseData = await response.json();
        setReybexData(responseData[0]);
        localStorage.setItem("LsNumber", JSON.stringify(responseData[0]));

        const MAPPEDHEAD =  MapLsOnHeader();

        console.log("Mapped Data HEADER: ", MAPPEDHEAD);

        if (MAPPEDHEAD) {

            localStorage.setItem('at-header-data', JSON.stringify(MAPPEDHEAD));

            //Mapping the Data initially
            setAtlasHeaderData({
                lrn: MAPPEDHEAD.lrn,
                bezugsnummer: MAPPEDHEAD.bezugsnummer,
                geschaftsvorgangRechnungspreis: MAPPEDHEAD.geschaftsvorgangRechnungspreis,
                gesamtRohmasse: MAPPEDHEAD.gesamtRohmasse,
                geschaftsvorgangWahrung: MAPPEDHEAD.geschaftsvorgangWahrung,
            })

        }

      } else {
        console.error("Error fetching the data");
      }
    }

    fetchReyBexData();
  }, [delnote]);



  const handleChange = (e) => {
    const { name, value } = e.target;

    // State aktualisieren
    setAtlasHeaderData((prevData) => {
        const updatedData = {
            ...prevData,
            [name]: value,
        };

        // Bestehende Daten aus localStorage holen
        const savedData = JSON.parse(localStorage.getItem('at-header-data')) || {};

        // Nur das geänderte Feld aktualisieren und die Struktur beibehalten
        const newData = {
            ...savedData,   // bestehende Daten behalten
            [name]: value,  // nur das geänderte Feld aktualisieren
        };

        // Aktualisierte Daten zurück in localStorage speichern
        localStorage.setItem('at-header-data', JSON.stringify(newData));

        return updatedData;
    });
};









  return (
    <>
      {reybexData && CustomerOptions ? (
        <div className="beo_mainpage_grid">
          <div className="header_left_column_wrapper">
            <h2>Allgemeine Informationen für die Ausfuhranmeldung</h2>
            <div className="form_wrapper">
              <Inputfield
                fieldname="Zeitpunkt der Anmeldung"
                placeholder="TT.MM.JJJJ"
                styling="disabled"
                type="date"
                readOnly={true}
                mandatory="none"
                name="headerdata1"
              />
              <TextDropDownButton
                fieldname="Ausfuhrland"
                zindex="1001"
                onChange={handleChange}
                options={
                  CustomerOptions.getOptionsForCompany
                    .find(item => item.type === "129")?.options || []
                }
              />                    
              <TextDropDownButton
                fieldname="Bestimmungsland"
                toggleDropdown={toggleDropdown}
                options={
                  CustomerOptions.getOptionsForCompany
                    .find(item => item.type === "104")?.options || []
                }
                name={"bestimmungsLand"}
                value={AtlasHeaderData.bestimmungsLand}
                onChange={handleChange}
              />         
            </div>
            <div className="form_wrapper">
              <h2>Anmeldeart</h2>
              <TextDropDownButton
                fieldname="Art der Anmeldung"
                zindex="999"
                index={3}
                onChange={handleChange}
                options={
                  CustomerOptions.getOptionsForCompany
                    .find(item => item.type === "133")?.options || []
                }
              />                    
            </div>
            <div className="form_wrapper">
              <h2>Allgemeine Angaben</h2>
              <Inputfield
                fieldname="Datum des Augangs"
                type="date"
                styling="disabled"
                readOnly={true}
                mandatory="none"
              />
              <TextDropDownButton
                fieldname="Sicherheit"
                zindex="998"
                index={5}
                toggleDropdown={toggleDropdown}
                onChange={handleChange}
                options={
                  CustomerOptions.getOptionsForCompany
                    .find(item => item.type === "108")?.options || []
                }
              />       
              <Inputfield
                fieldname="Maßgebliches Datum"
                type="date"
                styling="disabled"
                readOnly={true}
                mandatory="none"
              />
              <TextDropDownButton
                fieldname="Container-Indikator"
                zindex="998"
                index={5}
                toggleDropdown={toggleDropdown}
                onChange={handleChange}
                options={
                  CustomerOptions.getOptionsForCompany
                    .find(item => item.type === "125")?.options || []
                }
              />  
              <Inputfield
                fieldname="Registriernummer (extern)"
                type="text"
                mandatory="mandatory"
              />
              <Inputfield
                fieldname="Bezugsnummer (LRN)"
                type="text"
                mandatory="mandatory"
                value={AtlasHeaderData.lrn}
                name="lrn"
                onChange={handleChange}
              />
              <Inputfield
                fieldname="Referenznummer der Sendung (UCR)"
                type="text"
                mandatory="none"
                value={AtlasHeaderData.bezugsnummer}
                name="bezugsnummer"
                onChange={handleChange}
              />
              <TextDropDownButton
                fieldname="Beförderungskosten (Zahlungsart)"
                zindex="996"
                index={7}
                onChange={handleChange}
                options={
                  CustomerOptions.getOptionsForCompany
                    .find(item => item.type === "137")?.options || []
                }
              />     
              <TextDropDownButton
                fieldname="Indikator für Besondere Umstände"
                zindex="995"
                index={8}
                isOpen={openDropdownIndex === 8}
                toggleDropdown={toggleDropdown}
              />     
              <Inputfield
                fieldname="Gesamtrohmasse"
                type="text"
                mandatory="mandatory"
                value={AtlasHeaderData.gesamtRohmasse}
                name="gesamtRohmasse"
                onChange={handleChange}
              />
              <TextDropDownButton
                fieldname="Beteiligten-Konstellation"
                zindex="994"
                index={9}
                toggleDropdown={toggleDropdown}
                onChange={handleChange}
                options={
                  CustomerOptions.getOptionsForCompany
                    .find(item => item.type === "106")?.options || []
                }
                name="beteiligtenKonstellation"
                value={AtlasHeaderData.beteiligtenKonstellation}
              />  
            </div>
            <div className="form_wrapper">
              <h2>Angaben zur Gestellung</h2>
              <Inputfield
                fieldname="Zeitpunkt der Anmeldung"
                placeholder="TT.MM.JJJJ"
                styling="disabled"
                type="date"
                readOnly={true}
                mandatory="none"
              />
              <Inputfield
                fieldname="Zeitpunkt der Anmeldung"
                placeholder="TT.MM.JJJJ"
                styling="disabled"
                type="date"
                readOnly={true}
                mandatory="none"
              />
            </div>
            <div className="form_wrapper">
              <h2>Angaben zum Beförderungsmittel</h2>
              <TextDropDownButton
                fieldname="Inländischer Verkehrszweig"
                zindex="993"
                index={10}
                toggleDropdown={toggleDropdown}
                onChange={handleChange}
                name="beforderungsmittelImInlandVerkehrszweig"
                value={AtlasHeaderData.beforderungsmittelImInlandVerkehrszweig}
                options={
                  CustomerOptions.getOptionsForCompany
                    .find(item => item.type === "110")?.options || []
                }
              />
              <TextDropDownButton
                fieldname="Verkehrszweig an der Grenze"
                zindex="992"
                index={11}
                isOpen={openDropdownIndex ===11}
                toggleDropdown={toggleDropdown}
                                options={
                  CustomerOptions.getOptionsForCompany
                    .find(item => item.type === "110")?.options || []
                }
                onChange={handleChange}
                name="verkehrszweigAnDerGrenze"
                value={AtlasHeaderData.verkehrszweigAnDerGrenze}
              /> 
              <TextDropDownButton
                fieldname="Grenzüberschreitendes aktives Beförderungsmittel / Art der Identifikation"
                zindex="991"
                index={12}
                toggleDropdown={toggleDropdown}
                onChange={handleChange}
                options={
                  CustomerOptions.getOptionsForCompany
                    .find(item => item.type === "111")?.options || []
                }
              />
              <TextDropDownButton
                fieldname="Grenzüberschreitendes aktives Beförderungsmittel / Staatszugehörigkeit"
                zindex="989"
                index={14}
                isOpen={openDropdownIndex === 14}
                toggleDropdown={toggleDropdown}
              />      
            </div>
            <div className="form_wrapper">
              <h2>Angaben zu den Dienststellen</h2>
              <TextDropDownButton
                fieldname="Ausfuhrzollstelle"
                zindex="988"
                index={15}
                onChange={handleChange}
                options={
                  CustomerOptions.getOptionsForCompany
                    .find(item => item.type === "123")?.options || []
                }
                zIndex="1000"
              />
              <TextDropDownButton
                fieldname="Ausfuhrzollstelle für die ergänzende/ersetzende anmeldung"
                zindex="987"
                index={16}
                onChange={handleChange}
                options={
                  CustomerOptions.getOptionsForCompany
                    .find(item => item.type === "151")?.options || []
                }
              /> 
              <TextDropDownButton
                fieldname="Vorgesehene Ausgangszollstelle"
                zindex="986"
                index={17}
                onChange={handleChange}
                options={
                  CustomerOptions.getOptionsForCompany
                    .find(item => item.type === "122")?.options || []
                }
                zIndex="1000"
              />
              <TextDropDownButton
                fieldname="Tatsächliche Ausgangszollstelle"
                zindex="985"
                index={18}
                isOpen={openDropdownIndex === 18}
                toggleDropdown={toggleDropdown}
              />
              <TextDropDownButton
                fieldname="Gestellungszollstelle"
                zindex="984"
                index={19}
                onChange={handleChange}
                options={
                  CustomerOptions.getOptionsForCompany
                    .find(item => item.type === "152")?.options || []
                }
              />      
            </div>
            <div className="form_wrapper">
              <h2>Angaben zu dem Geschäftsvorgang</h2>
              <TextDropDownButton
                fieldname="Art des Geschäfts"
                toggleDropdown={toggleDropdown}
                zindex="983"
                index={20}
                onChange={handleChange}
                options={
                  CustomerOptions.getOptionsForCompany
                    .find(item => item.type === "105")?.options || []
                }
              />
              <Inputfield
                fieldname="In Rechnung gestellter Gesamtbetrag"
                type="text"
                mandatory="mandatory"
                value={AtlasHeaderData.inRechnunggestellterGesamtbetrag}
                name="inRechnunggestellterGesamtbetrag"
                onChange={handleChange}
              />
              <TextDropDownButton
                fieldname="Rechnungswährung"
                toggleDropdown={toggleDropdown}
                onChange={handleChange}
                zindex="982"
                index={21}
                options={
                  CustomerOptions.getOptionsForCompany
                    .find(item => item.type === "114")?.options || []
                }
              /> 
            </div>
            <div className="form_wrapper">
              <h2>Angaben zu den Lieferbedingungen</h2>
              <TextDropDownButton
                fieldname="Incoterms-Code"
                zindex="981"
                index={22}
                onChange={handleChange}
                options={
                  CustomerOptions.getOptionsForCompany
                    .find(item => item.type === "113")?.options || []
                }
              />
              <TextDropDownButton
                fieldname="UN/Locode"
                zindex="980"
                index={23}
                onChange={handleChange}
                options={
                  CustomerOptions.getOptionsForCompany
                    .find(item => item.type === "153")?.options || []
                }
              />
              <TextDropDownButton
                fieldname="Land"
                zindex="978"
                index={25}
                isOpen={openDropdownIndex ===25}
                toggleDropdown={toggleDropdown}
                options={
                  CustomerOptions.getOptionsForCompany
                    .find(item => item.type === "128")?.options || []
                }
                name={"lieferbedingungLand"}
                value={AtlasHeaderData.lieferbedingungLand}
                onChange={handleChange}
              />
              <Inputfield
                fieldname="Text"
                type="text"
                mandatory="none"
                styling="disabled"
                readOnly={true}
              />
            </div>
          </div>
          <div className="header_right_column_wrapper">
            <h2>Beteiligtenkonstelation</h2>
            <div className="accordion">
              <AccordionForm
                headline="Empfänger"
              />
              <AccordionForm
                headline="Anmelder"
              />
              <AccordionForm
                headline="Vertreter"
              />
              <AccordionForm
                headline="Subunternehmer"
              />
              <AccordionForm
                headline="Ausführer"
              />
              <AccordionForm
                headline="Außenwirtschaftlicher Ausführer"
              />                    
              <AccordionForm
                headline="Beförderer"
              />
              <AccordionForm
                headline="Versender"
              />
            </div>
            <div className="form_wrapper">
              <h2>Angaben zum Warenort</h2>
              <TextDropDownButton
                fieldname="Art des Ortes"
                zindex="977"
                index={26}
                isOpen={openDropdownIndex === 26}
                toggleDropdown={toggleDropdown}
              />
              <TextDropDownButton
                fieldname="Art der Ortsbestimmung"
                zindex="976"
                index={27}
                isOpen={openDropdownIndex ===27}
                toggleDropdown={toggleDropdown}
              />
              <Inputfield
                fieldname="Bewilligungsnummer"
                type="text"
                mandatory="none"
                styling="disabled"
                readOnly={true}
              />
              <TextDropDownButton
                fieldname="UN/Locode"
                zindex="974"
                index={29}
                isOpen={openDropdownIndex === 29}
                toggleDropdown={toggleDropdown}
              />
              <Inputfield
                fieldname="GNNS Breite"
                type="text"
                mandatory="none"
                styling="disabled"
                readOnly={true}
              />
              <Inputfield
                fieldname="GNNS Länge"
                type="text"
                mandatory="none"
                styling="disabled"
                readOnly={true}
              />
            </div>
            <div className="accordionwrapper">
              <div className="accordion">
                <AccordionForm
                  headline="Warenort / Adresse"
                />
              </div>
            </div>
          </div>
        </div>
      ) : ""}
    </>
  );
}

export default HeaderInformation;
