
export function MapLsOnHeader() {

    const ReyBeyData = JSON.parse(localStorage.getItem('LsNumber'));

    if (ReyBeyData) {

        const mappedData = {
            bestimmungsLand: ReyBeyData.customerCountry.code || "", // Werte aus ReyBeyData übernehmen, falls vorhanden
            container: "",
            beteiligtenKonstellation: "",
            lrn: ReyBeyData.docNumber || "",
            bezugsnummer: ReyBeyData.docNumber || "",
            bewilligungZugelassenerAusfuhrer: "",
            inRechnunggestellterGesamtbetrag: String(ReyBeyData.grossAmount) || "",
            beforderungsmittelImInlandVerkehrszweig: "",
            beforderungsmittelderGrenzeVerkehrszweig: "",
            beforderungsmittelderGrenzeArt: "",
            beforderungsmittelderGrenzeKennzeichen: "",
            beforderungsmittelderGrenzeStaatszugehorigkeit: "",
            warenortLadeortCode: "",
            ausfuhrzollstelleDienststellennummer: "",
            vorgeseheneAusgangszollstelleDienststellennummer: "",
            geschaftsvorgangArt: "",
            geschaftsvorgangWahrung: ReyBeyData.currency.code || "",
            gesamtRohmasse: ReyBeyData.grossWeight || "",
            lieferbedingungIncotermCode: "",
            lieferbedingungTextTeil:"",
            lieferbedingungOrt: "",
            lieferbedingungLand: "",
            ausfuhrland: "",
            kennnummerderSendung: "",
            sicherheit: "2",
            besondererTatbestandKennzeichen: "",
        };

        return mappedData;
    }

    return null; // Falls ReyBeyData nicht existiert, gib null zurück
}
