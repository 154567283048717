import './TextArea.css';

const TextArea = ({
    fieldname="name",
    maxLength=1000,
    value,
    onChange,
    name
}) => {

    return (
        <>
            <div className="rb_text_area_wrapper">
                <div className="rb_fieldname_text_area_wrapper">
                    <span>{fieldname}</span>
                </div>
                <div className="rb_text_area_input_field_wrapper">
                    <textarea onChange={onChange} name={name} value={value} maxLength={maxLength}></textarea>
                </div>
            </div>
        </>
    )

}

export default TextArea;