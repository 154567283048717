import React from 'react';

//importing the css
import '../../css/Loading/Loading.css';

//importing loading animation
import loading from './loading.gif'

function FullPageLoading(props) {
    return ( 
        <>
        <div className="main_loading_wrapper">
            <div className="darkerbg">

            </div>

            <div className="content_wrapper">
                <div className="loading_box">
                    <div className="wrapper_box_loading_animation">
                        <div className="headline">
                            <img src={loading} alt="loadingAnimation" />
                            <span>{props.loadingtitle}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        </>
     );
}

export default FullPageLoading;