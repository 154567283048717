import { configureStore } from '@reduxjs/toolkit';
import { CustomerOptions, optionsDataReducer } from './reducer/AtlasApiReducer';



//this is for creating the redux store
export const store = configureStore({


    reducer: {
        CustomerOptions: CustomerOptions,
        optionsData: optionsDataReducer,
    },


})