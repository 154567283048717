import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

//importing the Compoenents
import MainHeader from '../../components/header/MainHeader';
import HeadLine from '../../components/toolbar/HeadLine';
import ToolBar from '../../components/toolbar/ToolBar';
import FullPageLoading from '../../components/Loadinganimation/FullPageLoading';

//Importing the css
import '../../css/section/general/PendingDeliveries.css';

import React from 'react';

import base from '../../apis/base.json';
import { toast } from 'react-toastify';

function PendingDeliveries() {

    const [pendingDeliveries, setPendingDeliveries] = useState();
    const [LsNumber, SetLsNumber] = useState();
    const [reyBexId, setReyBexId] = useState();

    const ListOfNotEu = 
    [
        {        
            "code": "DE", // Deutschland
        },
        {        
            "code": "AT", // Österreich
        },
        {        
            "code": "BE", // Belgien
        },
        {        
            "code": "BG", // Bulgarien
        },
        {        
            "code": "CY", // Zypern
        },
        {        
            "code": "CZ", // Tschechische Republik
        },
        {        
            "code": "DK", // Dänemark
        },
        {        
            "code": "EE", // Estland
        },
        {        
            "code": "FI", // Finnland
        },
        {        
            "code": "FR", // Frankreich
        },
        {        
            "code": "GR", // Griechenland
        },
        {        
            "code": "HR", // Kroatien
        },
        {        
            "code": "HU", // Ungarn
        },
        {        
            "code": "IE", // Irland
        },
        {        
            "code": "IT", // Italien
        },
        {        
            "code": "LV", // Lettland
        },
        {        
            "code": "LT", // Litauen
        },
        {        
            "code": "LU", // Luxemburg
        },
        {        
            "code": "MT", // Malta
        },
        {        
            "code": "NL", // Niederlande
        },
        {        
            "code": "PL", // Polen
        },
        {        
            "code": "PT", // Portugal
        },
        {        
            "code": "RO", // Rumänien
        },
        {        
            "code": "SK", // Slowakei
        },
        {        
            "code": "SI", // Slowenien
        },
        {        
            "code": "ES", // Spanien
        },
        {        
            "code": "SE", // Schweden
        },
        {        
            "code": "IS", // Island (EFTA)
        },
        {        
            "code": "LI", // Liechtenstein (EFTA)
        },
        {        
            "code": "NO", // Norwegen (EFTA)
        },
        {        
            "code": "CH", // Schweiz (EFTA)
        }
    ];
    


    React.useEffect(() => {

        const getPendingDeliveries = async () => {
            try {
                // Token aus dem Local Storage abrufen
                const token = localStorage.getItem("Rtoken");
    
                // Anfrage an die API senden
                const response = await fetch(`${base.base_reybex}domains/salesHead?docType.name=eq(Lieferschein)`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Basic ${token}`
                    }
                });
    
                // Antwort überprüfen und Daten verarbeiten
                if (response.ok) {
                    const data = await response.json();
                
                    //Filter for the export relevant
                    if (data) {
                        const filteredDeliveries = data.filter((del) => {
                            return !ListOfNotEu.some((euCountry) => euCountry.code === del?.customerCountry?.code);
                        });
                
                        // set filtered data to pending deliveries
                        setPendingDeliveries(filteredDeliveries);
                        toast.info('Daten erfolgreich geladen', {
                            position: 'bottom-right',
                        });
                    } else {
                        // Handle the case when data is undefined or null
                        setPendingDeliveries([]);
                    }
                } else {
                    throw new Error('Fehler beim Abrufen der Daten');
                }
            } catch (error) {
                console.error(error);
            }
        };
    
        getPendingDeliveries();
    
    }, []);

    const goTo = useNavigate();
    //open an atlas message: 
    React.useEffect(() => {
        if (LsNumber && reyBexId) {
            goTo(`/kopfdatenatlasausfuhr/${LsNumber}/${reyBexId}`);
        }
    }, [LsNumber, goTo, reyBexId]);

    const openAtlasMessage = (lsnumber, reybexlsid) => {
        SetLsNumber(lsnumber);
        setReyBexId(reybexlsid)
    };

    //creating empty Atlas - Message
    const CreateAtlasMessage = (delnote) => {
        goTo(`kopfdatenatlasausfuhr/${delnote}`);
    } 


    // Searchlogic for Pending deliveries
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (value) => {
      setInputValue(value); 
    };

    console.log("Input Value", inputValue);


    return ( 
        <>
        <div className="wrapper">
            <MainHeader/>
        </div>
        <div className="sectionwrapper">
            <div className="wrapper">
                <HeadLine headline="Arbeitsvorrat"/>
                <ToolBar
                onClickCreate={CreateAtlasMessage}
                onInputChange={handleInputChange}
                />
            </div>


            {pendingDeliveries ? 
            (<>
            <div className="pendingdeliveries_wrapper">
                <div className="tablebox">
                    <table>
                        <thead>
                            <tr>
                                <th>Nr.</th>
                                <th>Lieferschein Nr.</th>
                                <th>Erstellt am.</th>
                                <th>Kunde</th>
                                <th>Bestimmungsland</th>
                                <th>Positionen</th>
                                <th>Rechnungsbetrag</th>
                            </tr>
                        </thead>

                        <tbody>
                            {pendingDeliveries?.map((deliverynotes, index) => (
                            <tr key={index} onDoubleClick={() => openAtlasMessage(deliverynotes.docNumber, deliverynotes.id)}>
                                <td>{index + 1}</td>
                                <td>{deliverynotes.docNumber}</td>
                                <td>{deliverynotes.dateCreated}</td>
                                <td>{deliverynotes.customer.name}</td>
                                <td>{`${deliverynotes.customerCountry.name}`}</td>
                                <td>{deliverynotes.items.length}</td>
                                <td>{deliverynotes.grossAmount.toFixed(2) + "€"}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            </>) :

            
            (<>
            <FullPageLoading loadingtitle="Hole Lieferscheine"/>
            </>)}            
        </div>
        </>
     );
}

export default PendingDeliveries;


